define("app-web/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    inclusion: '{description} no esta incluido en la lista',
    exclusion: '{description} está reservado',
    invalid: '{description} no es válido',
    confirmation: '{description} no coincide con {on}',
    accepted: '{description} debe ser aceptada',
    empty: '{description} no puede estar vacío',
    blank: '{description} debe estar vacío',
    present: '{description} no puede estar vacío',
    collection: '{description} debe ser una colección',
    singular: '{description} no puede ser una colección',
    tooLong: '{description} es demasiado largo (el máximo es {max})',
    tooShort: '{description} es demasiado corto (el minimo es {min})',
    between: '{description} debe estar entre {min} y {max}',
    before: '{description} debe estar antes de {before}',
    onOrBefore: '{description} debe ser en o antes de {onOrBefore}',
    after: '{description} debe ser después de {after}',
    onOrAfter: '{description} debe ser en o después de {onOrAfter}',
    wrongDateFormat: '{description} debe tener el formato de {format}',
    wrongLength: '{description} es la longitud incorrecta (debería ser {is})',
    notANumber: '{description} debe ser un número',
    notAnInteger: '{description} debe ser un número entero',
    greaterThan: '{description} debe ser mayo a {gt}',
    greaterThanOrEqualTo: '{description} debe ser mayor o igual a {gte}',
    equalTo: '{description} debe ser igual a {is}',
    lessThan: '{description} debe ser menor a {lt}',
    lessThanOrEqualTo: '{description} debe ser menor o igual a {lte}',
    otherThan: '{description} debe ser distinto de {value}',
    odd: '{description} debe ser impar',
    even: '{description} debe ser par',
    positive: '{description} debe ser positivo',
    multipleOf: '{description} debe ser multiplo de {multipleOf}',
    date: '{description} debe ser una fecha válida',
    email: '{description} debe ser una dirección de correo electrónico válida',
    phone: '{description} debe ser un número de teléfono válido',
    url: '{description} debe ser una url válida'
  };
  _exports.default = _default;
});