define("app-web/components/head-content", ["exports", "ember", "app-web/templates/head"], function (_exports, _ember, _head) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    tagName: '',
    model: _ember.default.inject.service('head-data'),
    layout: _head.default
  });

  _exports.default = _default;
});