define("app-web/validations/experiencia-laboral", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    nombreEmpresa: (0, _validators.validatePresence)(true),
    puesto: (0, _validators.validatePresence)(true),
    fechaIngreso: (0, _validators.validatePresence)(true),
    fechaEgreso: (0, _validators.validatePresence)(true),
    motivoRetiro: (0, _validators.validatePresence)(true),
    telefonosEmpresa: [(0, _validators.validateLength)({
      min: 1
    }), (0, _validators.validatePresence)({
      presence: true
    })]
  };
  _exports.default = _default;
});