define("app-web/initializers/asset-map", ["exports", "rsvp", "jquery", "@ember/utils", "app-web/services/asset-map", "app-web/config/environment", "@ember/debug"], function (_exports, _rsvp, _jquery, _utils, _assetMap, _environment, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    // if we're in an engine return early as engines
    // don't need to defer their readiness.
    if (!app.deferReadiness) {
      return;
    }

    app.deferReadiness();
    const useAssetMap = (0, _utils.isNone)(_environment.default.theme.useAssetMap) ? _environment.default.environment === 'production' : _environment.default.theme.useAssetMap;

    if (!useAssetMap) {
      app.register('service:asset-map', _assetMap.default);
      app.advanceReadiness();
      return;
    }

    const promise = new _rsvp.default.Promise((resolve, reject) => {
      _jquery.default.getJSON('/assets/assetMap.json', resolve).fail(reject);
    });
    promise.then((map = {}) => {
      const prepend = _environment.default.theme.assetPrepend || map.prepend;

      _assetMap.default.reopen({
        assetMapHash: map.assets,
        prepend,
        enabled: true
      });
    }, () => {
      (true && (0, _debug.warn)('Error loading assetMap.json. Did you forget to set:\nfingerprint: {\n fingerprintAssetMap=true\n}\nin your ember-cli-build file?', {
        id: 'ember-theme-changerr.asset-map'
      }));
    }).then(() => {
      app.register('service:asset-map', _assetMap.default);
      app.advanceReadiness();
    });
  }

  var _default = {
    name: 'asset-map',
    initialize
  };
  _exports.default = _default;
});