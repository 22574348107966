define("app-web/instance-initializers/theme-init", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    applicationInstance.lookup('service:theme-changer')._generateStyleTag();
  }

  var _default = {
    name: 'ember-theme-changerr-init',
    initialize
  };
  _exports.default = _default;
});