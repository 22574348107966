define("app-web/services/loader", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class LoaderService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "loading", false);
    }

    setLoading(loading = true) {
      (0, _object.set)(this, 'loading', loading);
    }

    setIsLoading() {
      (0, _object.set)(this, 'loading', true);
    }

    setNotLoading() {
      (0, _object.set)(this, 'loading', false);
    }

  }

  _exports.default = LoaderService;
});