define("app-web/templates/head", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ow28uBBr",
    "block": "[[[10,\"link\"],[14,\"rel\",\"stylesheet\"],[15,6,[29,[[33,0,[\"themeHref\"]]]]],[12],[13],[1,\"\\n\"]],[],false,[\"model\"]]",
    "moduleName": "app-web/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});