define("app-web/validators/list-of-emails", ["exports", "@ember/utils", "@ember/array", "ember-changeset-validations/utils/validation-errors"], function (_exports, _utils, _array, _validationErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateListOfEmails;

  function validateListOfEmails(options = {}) {
    let {
      allowBlank,
      regex,
      inverse = false
    } = options;
    return (key, value) => {
      if (allowBlank && (0, _utils.isNone)(value)) {
        return true;
      }

      if (!regex) {
        regex = /^[ña-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
      }

      let isValidEmail = true;
      let BreakException = {};

      try {
        (0, _array.A)(value).forEach(item => {
          if (regex.test(item) === inverse) {
            options.value = item;
            options.type = 'email';

            if (inverse) {
              options.type = 'invalid';
            }

            isValidEmail = (0, _validationErrors.default)(key, options);
            throw BreakException;
          }
        });
      } catch (e) {
        return isValidEmail;
      }

      return true;
    };
  }
});