define("app-web/serializers/application", ["exports", "@ember-data/serializer/json-api", "@ember/string"], function (_exports, _jsonApi, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSerializer extends _jsonApi.default {
    modelNameFromPayloadKey(key) {
      return (0, _string.dasherize)(key);
    }

  }

  _exports.default = ApplicationSerializer;
});