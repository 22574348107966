define("app-web/validators/dependent-presence", ["exports", "@ember/object", "@ember/utils", "ember-changeset-validations/validators"], function (_exports, _object, _utils, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateCustomPresence;

  function validateCustomPresence(options = {}) {
    let {
      dependsOn,
      changeset
    } = options;
    let wrappedValidateFunction = (0, _validators.validatePresence)({
      presence: true
    });

    if ((0, _utils.isBlank)(dependsOn) || (0, _utils.isBlank)(changeset)) {
      throw new Error(`Missing arguments for validator 'presence-if-presence'.`);
    }

    return (key, value, oldValue, changes, content) => {
      if ((0, _utils.isBlank)((0, _object.get)(changeset, dependsOn))) {
        return wrappedValidateFunction(key, value, oldValue, changes, content);
      }

      return true;
    };
  }
});