define("app-web/components/file-uploader", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="form-group form-group-sm {{if this.hasErrors 'has-error'}}">
    <label class="{{this.labelClasses}}">
      {{@label}}
    </label>
    <div class="{{this.divClasses}}">
      {{#if this.file}}
        <input type="text" aria-label="Label Text Here"  class="form-field" disabled="disabled" value={{this.file.name}} />
  
        <BsButton @size="xs" @type="danger" @icon="fa fa-trash" @onClick={{this.clear}}>
          Limpiar
        </BsButton>
      {{else}}
        <FileField
          @onFilesChange={{this.updateFiles}}
          @multiple={{this.multiple}}
          @disabled={{this.disabled}}
          @classNames="form-control"
        />
        <small>El tamaño máximo es {{this.maxSizeFile}}MB.</small>
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "I1zeGrYc",
    "block": "[[[10,0],[15,0,[29,[\"form-group form-group-sm \",[52,[30,0,[\"hasErrors\"]],\"has-error\"]]]],[12],[1,\"\\n  \"],[10,\"label\"],[15,0,[29,[[30,0,[\"labelClasses\"]]]]],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[30,0,[\"divClasses\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"file\"]],[[[1,\"      \"],[10,\"input\"],[14,\"aria-label\",\"Label Text Here\"],[14,0,\"form-field\"],[14,\"disabled\",\"disabled\"],[15,2,[30,0,[\"file\",\"name\"]]],[14,4,\"text\"],[12],[13],[1,\"\\n\\n      \"],[8,[39,1],null,[[\"@size\",\"@type\",\"@icon\",\"@onClick\"],[\"xs\",\"danger\",\"fa fa-trash\",[30,0,[\"clear\"]]]],[[\"default\"],[[[[1,\"\\n        Limpiar\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],null,[[\"@onFilesChange\",\"@multiple\",\"@disabled\",\"@classNames\"],[[30,0,[\"updateFiles\"]],[30,0,[\"multiple\"]],[30,0,[\"disabled\"]],\"form-control\"]],null],[1,\"\\n      \"],[10,\"small\"],[12],[1,\"El tamaño máximo es \"],[1,[30,0,[\"maxSizeFile\"]]],[1,\"MB.\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@label\"],false,[\"if\",\"bs-button\",\"file-field\"]]",
    "moduleName": "app-web/components/file-uploader.hbs",
    "isStrictMode": false
  });

  let FileUploaderComponent = (_class = class FileUploaderComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "file", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);
    }

    get widgetSize() {
      return this.args.widgetSize ?? 'sm-8';
    }

    get labelSize() {
      return this.args.labelSize ?? 'sm-4';
    }

    get disabled() {
      return this.args.disabled ?? false;
    }

    get multiple() {
      return this.args.multiple ?? false;
    }

    get maxSizeFile() {
      return this.args.maxSizeFile ?? 10;
    }

    get isUploading() {
      return this.uploader.isUploading;
    }

    get labelClasses() {
      return ['control-label', `col-${this.labelSize}`, 'no-padding-left', 'no-padding-right'].join(' ');
    }

    get divClasses() {
      return [`col-${this.widgetSize}`].join(' ');
    }

    get hasErrors() {
      if (!this.filesModel) {
        return false;
      }

      return Object.keys(this.filesModel._errors).length;
    }

    updateFiles(filesData) {
      let size = filesData[0].size;
      let sizeMb = size / 1024 / 1024;

      if (this.maxSizeFile < sizeMb) {
        this.toast.warning(`El tamaño máximo es ${this.maxSizeFile}MB.`);
        return;
      }

      if (this.args.onFilesChange) {
        this.file = filesData[0];
        this.args.onFilesChange(this.file);
      }
    }

    clear() {
      this.file = null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "file", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateFiles", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class);
  _exports.default = FileUploaderComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FileUploaderComponent);
});