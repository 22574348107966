define("app-web/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LRz7HI7x",
    "block": "[[[1,[28,[35,0],[\"AppWeb\"],null]],[1,\"\\n\\n\"],[1,[34,1]],[1,\"\\n\\n\"],[41,[33,3,[\"loading\"]],[[[1,\"  \"],[1,[28,[35,4],null,[[\"isShow\"],[true]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,5],null,[[\"@type\",\"@backgroundColor\",\"@collapsed\",\"@onCollapse\",\"@onExpand\"],[\"dark\",\"secondary\",true,[28,[37,6],[[28,[37,7],[[30,0,[\"collapsed\"]]],null],true],null],[28,[37,6],[[28,[37,7],[[30,0,[\"collapsed\"]]],null],false],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"navbar-header\"],[12],[1,\"\\n    \"],[1,[30,1,[\"toggle\"]]],[1,\"\\n    \"],[8,[39,8],[[24,0,\"navbar-brand\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"]],[\"navbar\"],false,[\"page-title\",\"head-layout\",\"if\",\"loader\",\"ember-cli-spinner\",\"bs-navbar\",\"fn\",\"mut\",\"link-to\",\"component\",\"-outlet\"]]",
    "moduleName": "app-web/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});