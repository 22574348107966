define("app-web/templates/not-found", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bdKAGkug",
    "block": "[[[1,[28,[35,0],[\"Not found\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"page-wrap d-flex flex-row align-items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row justify-content-center\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12 text-center\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"display-1 d-block\"],[12],[1,\"404\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"mb-4 lead\"],[12],[1,\"Parece que no hay nada por aquí.\"],[13],[1,\"\\n        \"],[8,[39,1],[[24,0,\"btn btn-link\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n          \"],[10,\"strong\"],[12],[1,\"\\n            Volver a inicio\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"page-title\",\"link-to\"]]",
    "moduleName": "app-web/templates/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});